import React, {useEffect} from 'react'

const Team = () => {

useEffect(()=>{
document.title = 'Projects | MALT ELECTRICAL ENGINEERING CO. LTD';

},[]);

  return (
    <section className='section-cover team-cover team-cover1' >
         <div className='content-container table-container'>
           <h2 className="home-h2 team-h2">Electrical Engineering Projects</h2>




  <table className='team-table team-table1'>
    
    <tr>
      <th>Month/Year</th>
      <th>Contract Identification</th>
      <th>Role of Bidder</th>
      <th>MV distance </th>
      <th>LV distance </th>
      <th>Contract amount (UGX)</th>
       <th>% COMPLETION </th>
    </tr>

     <tr>
      <td>March
2020
to
June
2020 </td>
      <td>New construction to power
line Uganda Embassy
Burundi 3km  </td>
      <td>Surveying, pegging,
installation and design
works. </td>
      <td>3</td>
      <td>  TX </td>
      <td> USD 95,320  </td>
        <td> 100%   </td>

    </tr>


    <tr>
      <td>Dec
2019
to
March
2020</td>
      <td>Installation of RMU SF6 and
cable laying 30KV in Burundi.
(1Km, Central Bank of
Burundi). 
</td>
      <td>Supply, installation and
design works. </td>
      <td>900m</td>
      <td>  10m </td>
      <td>USD 55,000</td>
        <td> 100%   </td>

    </tr>

    <tr>
      <td>June 2017 to July 2020</td>
      <td>Supply of Electrical Engineering Materials. (Power cables, power transformers and overhaud accessories 30kV to 110kV to Regideso Burundi and private sector)
</td>
      <td>Lot 2 and Lot 5</td>
      <td></td>
      <td>   </td>
      <td>USD 5,187,788</td>
        <td> 100%   </td>

    </tr>

    <tr>
      <td>June
2018
to date</td>
      <td><strong>Contract name:</strong> LOT 3B,
33kv/11kv with lv network
power extensions to
Kyenjojo, Kisolo, Kabale,
Ntungamo, Mbarara, Ibanda,
Kamwenge,Rukungiri districts<br></br>
<strong>Brief Description of the
Works performed by the
Bidder:</strong>Design, and survey
works Name of Employer:
NARI TECHNOLOGY CO
LTD Address: kampala
</td>
      <td>Surveying, pegging and
design works. </td>
      <td>740 </td>
      <td>  2319</td>
      <td>Ugx 2,032,049,900</td>
        <td>92%    </td>

    </tr>

    <tr>
      <td>May
2019
to date</td>
      <td><strong>Contract name: </strong>construction
of 33kv and 11kv rural
electrification schemes in
western Uganda, Kalungu.
Masaka, Sembab Ule,
Lyantonde, Bukomansimbi
and Kiruhura districts.<br></br>
<strong>Brief Description of the
Works performed by the
Bidder: </strong>Design, survey and
construction works.<br></br>
<strong>Name of Employer:</strong> M&T
CONSTRUCTION CO.LTD
Address: PLOT 2D/2E
NAKASERO HILL ROAD,
KAMPALA 

</td>
      <td>Surveying, pegging and
design works.<br></br>
Contract number
REA/BADEA/OFID/WRKS/
15-16/00197</td>
      <td>124  </td>
      <td>  169</td>
      <td>Ugx 357,000,800=</td>
        <td>95%     </td>

    </tr>

     <tr>
      <td>OCT
2019
to date</td>
      <td><strong>Contract name:</strong> construction
of 33kv, installation of
transformers with associated
LV network under LOT 03;
Mbarara, Buhweju, Rubirizi,
Bushenyi, Shema, Isingiro,
Ntungamo and Kabale
districts.<br></br>
<strong>Brief Description of the
Works performed by the
Bidder: </strong>Design, survey and
construction works<br></br>
<strong>Name of Employer:</strong> DIMO
Address: NO.65 JATAWANA
RD, COLOMBO 14, SRI
LANKA 

</td>
      <td>Surveying, pegging and
design works.<br></br>
Contract number
REA/ /WRKS/18-
19/00289</td>
      <td>126   </td>
      <td>  258 </td>
      <td>Ugx 78,000,000</td>
        <td>93%    </td>

    </tr>

      <tr>
      <td>FEB
2019
to date</td>
      <td><strong>Contract name:</strong> construction
of low voltage off grid power
distribution networks in
various regions of Uganda
under lot 1,2 and 3. Kitgum
and Lamo districts.<br></br>
<strong>Brief Description of the
Works performed by the
Bidder:</strong> Design, survey and
construction works.<br></br>
<strong>Name of Employer:</strong>
WUNCH ENERGY Address:
4
TH FLOOR ACACIA MALL,
SUITE 355 

</td>
      <td>Surveying, pegging and
design works.<br></br>
Contract number
REA/WRKS/18-19/00174 </td>
      <td>0  </td>
      <td>  97 </td>
      <td>Ugx 39,400,000</td>
        <td>100%    </td>

    </tr>

     <tr>
      <td>MAY
to
AUG
2019</td>
      <td><strong>Contract name:</strong> 33kv
powerline relocation along
umeme section of
kapkyorwa-swam(73km).<br></br>
<strong>Brief Description of the
Works performed by the
Bidder:</strong> Design, survey and
construction works.<br></br>
<strong>Name of Employer:</strong>
MANTAB ENGINEERING
SERVICES LTD Address:
P.O BOX 5626, KAMPALAUGANDA

</td>
      <td>Surveying, pegging and
design works. </td>
      <td>27  </td>
      <td>  6 </td>
      <td>Ugx 29,200,000</td>
        <td>100%    </td>

    </tr>

     <tr>
      <td>SEPT
2018
to
JULY
2019</td>
      <td><strong>Contract name:</strong> Diversion of
affected powerline along
kabwoya-kyenjojo road,
hoima,kagadi,kibale and
Kyenjojo districts.<br></br>
<strong>Brief description of works
performed by the Bidder:</strong>
Design, survey and
construction works.<br></br>
<strong>Name of Employer:</strong>
MANTAB ENGINEERING
SERVICES LTD Address:
P.O BOX 5626, KAMPALAUGANDA 

</td>
      <td>Surveying, pegging and
design works. </td>
      <td>62   </td>
      <td>  13 </td>
      <td>Ugx 47,400,000</td>
        <td>100%    </td>

    </tr>

    <tr>
      <td>SEPT
2018
to
JULY
2019</td>
      <td><strong>Contract name:</strong> 33kv
powerline diversion along the
masaka-bukakata road
junction.<br></br>
<strong>Brief description of works
performed by the Bidder:</strong>
Design, survey and
construction works.<br></br>
<strong>Name of Employer:</strong>
MANTAB ENGINEERING
SERVICES LTD Address:
P.O BOX 5626, KAMPALAUGANDA 

</td>
      <td>Surveying, pegging and
design works.</td>
      <td>60  </td>
      <td>  0.5 </td>
      <td>Ugx 41,500,000</td>
        <td>98%    </td>

    </tr>

     <tr>
      <td>JULY
2016
to
APIRL
2017</td>
      <td><strong>Contract name:</strong>
FOURNITURE D'E
CONDUCTOURS, CABLES
ABC, ET
TRANSFOMATEURS.<br></br>
<strong>Brief Description of the
Works performed by the
Bidder: </strong>Material supply.<br></br>
<strong>Name of Employer:</strong>
REGIDESO Address:B.P
862 bujumbura

</td>
      <td>Material supply; cables,
transformers, conductors and
switch gears.</td>
      <td> </td>
      <td>   </td>
      <td>Ugx 986,000,760</td>
        <td>100%    </td>

    </tr>

      <tr>
      <td>Feb -
2015
to July
2016</td>
      <td><strong>Contract name:</strong> Hoima
Projects.<br></br>
<strong>Brief Description of the
Works performed by the
Bidder:</strong> Hv and associated
low voltage power lines
construction and
commissioning.<br></br>
<strong>Name of Employer: </strong>KEC
International 

</td>
      <td>General works on site i.e
pole erection, switch gear
and substation installation,
conductor string and site
commissioning</td>
      <td> </td>
      <td>   </td>
      <td>Ugx 437,300,452</td>
        <td>100%    </td>

    </tr>

     <tr>
      <td>March
-2013
to
2014-
AUG</td>
      <td><strong>Contract name:</strong>Wakiso
Projects i.e. Mpigi,Busunju.<br></br>
<strong>Brief Description of the
Works performed by the
Bidder:</strong> Construction of Hv
and Low voltage and
installation 42 transformers.<br></br>
<strong>Name of Employer:</strong> China
Jiangxi Uganda limited
Address: Kampala 

</td>
      <td>Design, survey, construction
and commissioning works </td>
      <td>90  </td>
      <td> 128  </td>
      <td>Ugx 1,765,500,000 </td>
        <td>100%    </td>

    </tr>

    <tr>
      <td>MAY
2010</td>
      <td><strong>Contract name:</strong> Security
Lights installation
Makerere University.<br></br>
<strong>Brief Description of the
Works performed by the
Bidder: </strong>installation of the
security lights on roads in
Makerere University.<br></br>
<strong>Name
of Employer: </strong>Arab
Contractors
Address: Kampala 

</td>
      <td>Installation of various
security lights and
commissioning.  </td>
      <td> </td>
      <td>   </td>
      <td>Ugx 56,000,000 </td>
        <td>100%    </td>

    </tr>

     <tr>
      <td>JAN-OCT
2011 </td>
      <td><strong>Contract name:</strong> Kisoro
(Lubugulitc) power line.<br></br>
<strong>Brief Description of the
Works performed by the
Bidder:</strong>
Construction of Hv and
Low voltage and
installation of
transformers.<br></br>
<strong>Name of Employer:</strong> A2Z
Mentainance and
Engineering services
Limited
Address: Kampala  

</td>
      <td>General works on site i.e.
pole erection, switch gear
and substation installation,
conductor string and site
commissioning </td>
      <td> 30 </td>
      <td>  15 </td>
      <td>Ugx 360,000,000 </td>
        <td>100%    </td>

    </tr>

    <tr>
      <td>2010-
Feb </td>
      <td><strong>Contract name:</strong> KikangatiRuyunguKyabukube-Kizobo to
Insigiro power line.<br></br>
<strong>Brief Description of the
Works performed by the
Bidder: </strong>Construction of Hv
and Low voltage and
installation of transformers
22km Hv.<br></br>
<strong>Name of Employer:</strong>
Power Engineering Limited
Address: Kampala   

</td>
      <td>General works on site i.e.
pole erection, switch gear
and substation installation,
conductor string and site
commissioning </td>
      <td> 22 </td>
      <td>  14 </td>
      <td>Ugx 98,007,000 </td>
        <td>100%    </td>

    </tr>

    <tr>
      <td>MAR
to DEC
2012 </td>
      <td><strong>Contract name:</strong>
Muguluska-Nsozibiiri Tc
power line.<br></br>
<strong>Brief Description of the
Works performed by the
Bidder:</strong> Construction of Hv
and Low voltage and
installation of transformers.<br></br>
<strong>Name of Employer:</strong>
Power Engineering Limited
Address: Kampala  

</td>
      <td>General works on site i.e
pole erection, switch gear
and substation installation,
conductor string and site
commissioning. </td>
      <td> 5 </td>
      <td>  7 </td>
      <td>Ugx 38,672,000 </td>
        <td>100%    </td>

    </tr>

  </table>

   <h2 className="home-h2 team-h2  team-h2a">Civil Engineering Projects</h2>

   <table className='team-table team-table1'>
    
    <tr>
      <th>Project Title</th>
      <th>Location</th>
      <th>Client</th>
      <th>Description </th>
      <th>Contract amount </th>
      <th>Date</th>
 
    </tr>
        
    <tr>
        <td>
            Construction of New
Facilities at the Trent
Memorial Secondary
School Lwemiyaga,
Sembabule District,
Central Uganda.<br></br>
Contract
No.IOCC/UOC12-2017

            </td>
    <td>
        Lwemiyaga
    </td>
    <td>
        Uganda
Orthodox
Church (UOC),
Attn:
International
Orthodox
Christian
Charities(IOC
C)Uganda
Projects,
P.O. Box 3970,
Kampala.
    </td>

    <td>
        Construction of a
girls dormitory,
toilet block and
rain water
harvesting
system.

    </td>
    <td>
        USD.92,252.02 </td>
    <td>
        5
TH Jan 2018
To
14
TH July
2018
    </td>


    </tr>

    <tr>
        <td>
            Civil Repair Works
and Erection of
Shelves at The
Records Centre
Repository – Mbarara
Regional Branch.
Proc.Ref.BOU/WRKS/
16-17/00002A

            </td>
    <td>
        Mbarara
    </td>
    <td>
       Bank of
Uganda<br></br>
P.O. Box 7120
Kampala.<br></br>
Tel:+256-414-
258441<br></br>
or +256-414-
345399<br></br>
Fax:+256-414-
234651

    </td>

    <td>
        Fabrication and
erection of
metallic shelves,
supply&
installation of fire
detection
system, fire
suppression
system, CCTV
cameras & all
necessary civil
works & painting.

    </td>
    <td>
       UGX.675,096,977.94 </td>
    <td>
        27TH July.2017
To
12TH July
2018
    </td>

    </tr>

    <tr>
        <td>
            Modification of A
Scanner Shed at
Malaba OSBP <br></br>
Proc.Ref:URA/WRKS/
CSD/17-18/01085


            </td>
    <td>
      Malaba
    </td>
    <td>
       Uganda
Revenue
Authority, P.O.
Box 7279,
Kampala.


    </td>

    <td>
        Concrete
demolitions,
installation of
cable sleeves, reconcreting,
construction of
R.C walls &
prefab container
installation &
associated
electrical works.


    </td>
    <td>
       UGX.506,716,083/=  </td>
    <td>
       April 2018
To
June 2018

    </td>

    </tr>

    <tr>
        <td>
           Reconstruction of A
Scanner Shed at
Busia OSBP -
Proc.Ref:URA/WRKS/
CSD/17-18/00818
   </td>
    <td>
      Busia
    </td>
    <td>
       Uganda
Revenue
Authority, P.O.
Box 7279,
Kampala


    </td>

    <td>
        Concrete
demolitions,
installation of
cable sleeves, reconcreting,
construction of
R.C walls &
prefab container
installation &
associated
electrical works.


    </td>
    <td>
       UGX.435,554,563  </td>
    <td>
      Jan 2018
To
March 2018


    </td>

    </tr>

    <tr>
        <td>
          Construction of FitOut for New Offices at
Plot 11-13 Mackenzie
Close
   </td>
    <td>
     Kololo -
Uganda
    </td>
    <td>
       United
Nations High
Commission
for Refugees
(UNHCR), P.O.
Box 3813,
Kampala
<br></br>
Contact
Person:
SyliviaNakitto


    </td>

    <td>
     ITB/UGA/LOG/02
3/2014
Aluminium
partitioning,
paved parking
construction,
painting,
installation of access control,
fire detection and
alarm systems,
electrical and
mechanical
works and ACs
installation.
CCTV, Local Area
Network (LAN).


    </td>
    <td>
      UGX.2,407,301,736 </td>
    <td>
  Dec.2014
To
March 2015


    </td>

    </tr>

    <tr>
        <td>
         Renovation of
Development House
on Plot 9-11,
Parliament Avenue
   </td>
    <td>
    Kampala -
Uganda
    </td>
    <td>
     The
Parliamentary
Commission,
P.O. Box 7178,
Kampala
Contact
Person:
Jane L.
Kibirige, Clerk
to Parliament


    </td>

    <td>
     PT/WRKS/ODB/1
2-13/00629
Mahogany
partitioning,
ceiling and walls
repainting , roof
waterproofing,
electrical,
mechanical and
Passenger Lift
installation.
CCTV Voice and
Data Installation


    </td>
    <td>
    UGX.2,406,838,560 </td>
    <td>
Sept.2013
To
Jan.2015



    </td>

    </tr>

     <tr>
        <td>
        Refurbishment/Partiti
oning Works for NIP
Building (Records
and Registry Section)

   </td>
    <td>
  Nakawa -
Uganda
    </td>
    <td>
    Uganda
Revenue
Authority, P.O.
Box 7279,
Kampala<br></br>
Contact
Person:
Jackson
Mukonyezi


    </td>

    <td>
   URA/WRKS/CSD/
12-13/00889
Steel frame
fabrication, fire
detection and
alarm
installation, LAN
installation and
general finishes


    </td>
    <td>
    UGX.1,636,285,253 </td>
    <td>
Oct.2013
to
Oct.2014
    </td>

    </tr>

      <tr>
        <td>
        Proposed Medical
School Building at
Mbale Regional
Referral Hospital on
Plot No.28-33, Kumi
Road

   </td>
    <td>
 Mbale -
Uganda
    </td>
    <td>
  Busitema
University,
P.O. Box 236,
Tororo.
Contact<br></br>
Person:
The University
Secretary
    </td>

    <td>
 BUT/WRKS/2014-
2015/00104
Construction of
Laboratory and
Lecture Complex
Phase l
 </td>
    <td>
    UGX.493,897,986 </td>
    <td>
July 2015 
    </td>

    </tr>

        <tr>
        <td>
        Re-development of
Kyabazinga’s Palace
at Igenge Hill -
Wanyange

   </td>
    <td>
Igenge Hill -
Wanyange -
Uganda
    </td>
    <td>
  Ministry of
Works and
Transport,
P.O. Box 10,
Entebbe<br></br>
Contact
Person:
Eng. P. Kikuyo

    </td>

    <td>
MOWT/WKS/07-
08/00238
Civil, building
and road works,
structural steel,
electrical
 </td>
    <td>
    UGX.4,023,043,433 </td>
    <td>
Nov.2008
to
Dec.2010
    </td>

    </tr>

     <tr>
        <td>
       Construction of
Headquarters
Building for Uganda
Police Force at Plot
42-49, Katalima Road,
Naguru, Kampala
Phase l


   </td>
    <td>
Kampala -
Uganda
    </td>
    <td>
  Uganda Police
Force, Estates
Unit, P.O. Box
7262, Kampala
Contact<br></br>
Person:
Mr. Richard Edyegu (ACP)


    </td>

    <td>
UPF/WRKS/06-
07/00073
Construction
 </td>
    <td>
    UGX.2,058,517,572 </td>
    <td>
Completed
Dec.2010
    </td>

    </tr>

    <tr>
        <td>
      Rehabilitation of
Moroto Barracks.


   </td>
    <td>
Moroto -
Uganda
    </td>
    <td>
Ministry of
Defence,
P.O. Box 3798,
Kampala


    </td>

    <td>
MD/WORKS/2007
-08/00030
Construction of
MT Workshop,
Renovation of
Senior Staff and
Junior Staff
Houses, Wards
for Officers,
Soldiers,
Maternity and
Mortuary, Senior
& Junior Officers
Messes,
Construction of
new General
Operational
Theatre,
Rehabilitation of
water
distribution,
drainage and
HV/LV Line
within Barracks.
Steel Portal
Frame and
cladding,
Roofing with Iron
Sheets.

 </td>
    <td>
   UGX.5,321,691,659 </td>
    <td>
June 2008
To 2013

    </td>

    </tr>

     <tr>
        <td>
   Urgent Repairs of
Matte–Sekanyonyi–
Kikonge Road (32km)
for


   </td>
    <td>

    </td>
    <td>
Uganda
National
Roads
Authority

    </td>

    <td>
Grading the
entire road
length, Drainage
Repairs and
Limited Spot Regravelling works.


 </td>
    <td>
   Ugx.224, 000,000 </td>
    <td>
April 2011 To
May 2012

    </td>

    </tr>

     

  </table>


         </div>

           
    </section>
  )
}

export default Team